<template>
    <div class="body_bg">
        <!--    消息提示-->
        <v-snackbar
                timeout="2000"
                :value="snackbarText"
                :color="textColor"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <div class="container">
            <div class="left">
                <div class="l_title">
                    <v-icon large color="#1989FA">mdi-home-city-outline</v-icon>
                    <div>闲置租赁场所风险管控系统</div>
                </div>
                <img src="~assets/img/home/img.png">
            </div>
            <div class="right">
                <div class="r_title">
                    <div>登录</div>
                </div>
                <div class="r_input">
                    <el-form :model="loginMsg" :rules="loginRules" ref="loginRules">
                        <div style="margin-bottom: 25px">
                            <el-form-item prop="phone">
                                <el-input placeholder="请输入手机号" v-model="loginMsg.phone">
                                    <template #prepend>账号</template>
                                </el-input>
                            </el-form-item>
                        </div>

                        <div style="margin-bottom: 25px">
                            <el-form-item prop="password">
                                <el-input placeholder="请输入密码" v-model="loginMsg.password" show-password>
                                    <template #prepend>密码</template>
                                </el-input>
                            </el-form-item>
                        </div>

                    </el-form>

                    <div class="r_input_text">
                        <el-checkbox
                                color="#009688"
                                v-model="checked"
                        >
                            <div style="font-size: 13px">7天免登录</div>
                        </el-checkbox>
                        <div style="color: #0486FE;font-size: 13px" @click="toFindPwd">忘记密码？</div>
                    </div>
                </div>
                <div class="r_bt text-center">
                    <v-btn
                            depressed
                            color="#E6F1FC"
                            width="88%"
                            height="40px"
                            dark
                            style="font-weight: bold;font-size: 16px;color:#1989FA "
                            @click="login"
                    >登录
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogin} from "@/network/Details/general_page/login";
    import {getMenu} from "../../network/Details/home";
    // 此处自定义校验手机号码js逻辑
    let phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
    let validatePhone = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('号码不能为空!'))
        }
        setTimeout(() => {
            if (!phoneReg.test(value)) {
                callback(new Error('请输入正确电话格式'))
            } else {
                callback()
            }
        }, 1000)
    }

    export default {
        name: "Login",
        data() {
            return {
                textColor: '',
                //密码显示
                show: false,
                //账号
                loginMsg: {
                    phone: '',
                    password: '',
                },
                loginRules: {
                    phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
                    password: [{required: true, message: '密码不能为空!', trigger: 'blur'}],
                },
                //提示
                snackbarShow: false,
                snackbarText: '',
                //复选框
                checked: false,
                isRemember: 0
            }
        },
        methods: {
            //登录
            login() {
                this.$refs.loginRules.validate((v) => {
                    if (!v) {
                        return;
                    }
                    if (this.checked === true) {
                        this.isRemember = 1
                    }
                    userLogin({
                        phone: this.loginMsg.phone,
                        password: this.loginMsg.password,
                        isRemember: this.isRemember
                    }).then(res => {
                        if (res.status === 200) {
                            window.localStorage.setItem('phone', this.loginMsg.phone);
                            window.localStorage.setItem('userId', res.data)
                            this.snackbarText = '登录成功！';
                            this.textColor = '#4CAF50'
                            this.snackbarShow = true;
                            getMenu({
                                userID: res.data
                            }).then(res => {
                                if (res.status === 200) {
                                    this.$store.commit("setMenu",res.data)
                                    // this.menuData = res.data
                                    this.$router.push(res.data[0].index)
                                }
                            })
                            // this.$router.push("/home");
                        } else {
                            console.log(res)
                            this.snackbarText = '登录失败！';
                            this.textColor = '#F44336'
                            this.snackbarShow = true;
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                })
            },
            //跳转到修改密码
            toFindPwd() {
                this.$router.push('/findPwd')
            },

        }
    }
</script>

<style lang="less" scoped>
    .body_bg {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        height: 100%;
        //background-image: linear-gradient(-225deg, #D4FFEC 0%, #57F2CC 48%, #4596FB 100%);

    }

    .container {
        width: 691px;
        height: 411px;
        border-radius: 21px;
        background: #ffffff;
        box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.31);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 15px;
        display: flex;
        flex-direction: row;

        .left {
            height: 360px;
            width: 330px;

            .l_title {
                height: 60px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                text-align: center;
                line-height: 60px;
                font-weight: bold;
                justify-content: space-evenly;
            }

            img {
                width: 280px;
                height: 300px;
                margin-left: 25px
            }
        }

        .right {
            height: 365px;
            width: 340px;
            border: #F6F6F6 1px solid;
            display: flex;
            flex-direction: column;

            .r_title {
                height: 60px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                //text-align: center;
                line-height: 60px;
                margin-top: 35px;
                font-weight: bold;
                justify-content: space-evenly;
            }

            .r_input {
                margin-top: 20px;
                width: 90%;
                margin-left: 6%;

                .r_input_text {
                    margin-top: 5px;
                    width: 90%;
                    height: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-left: 5%;
                }
            }

            .r_bt {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }

            .r_registered {
                margin-top: 10px;
                text-align: center;
                color: #666666;
                font-size: 13px;
            }

        }
    }
</style>
