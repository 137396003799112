import {request} from "@/network/request";

//登录
export function userLogin(userDate){
    return request({
        url:'user/login',
        method:'post',
        params:userDate
    })
}
