import {request} from "../request";

export function getPost(data) {
    return request({
        url: '/post/latestPost',
        method: 'get',
        params: data
    })
}
export function getMenu(data) {
    return request({
        url: '/navigateMenu/selectNavigateMenu',
        method: 'get',
        params: data
    })
}